import React from 'react';
import { ColorDataTableCode, Section, SectionSubhead } from 'docComponents';

const Content = ({ data, platform }) => {
  const buttonsData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Buttons'
  );
  const interjectionsData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Interjections'
  );
  const scrimData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Scrim'
  );
  const selectionData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Selection'
  );
  const linkData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Links'
  );

  return (
    <Section title="Component">
      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={buttonsData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={buttonsData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <ColorDataTableCode data={interjectionsData} platform={platform} />
      </Section>

      <Section>
        <ColorDataTableCode data={scrimData} platform={platform} />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={selectionData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={selectionData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={linkData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={linkData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Content;
