import React from 'react';
import { ColorDataTableCode, Section } from 'docComponents';

const Brand = ({ data, platform }) => {
  const brandData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Brand'
  );

  return (
    <Section title="Brand">
      <ColorDataTableCode data={brandData} platform={platform} />
    </Section>
  );
};

export default Brand;
