import React from 'react';
import { graphql } from 'gatsby';
import { PageWithSubNav, PageHero, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Colors" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Colors"
      />

      <PlatformTabs>
        <WebTab data={data} type="web" />
        <ReactNativeTab data={data} type="reactnative" />
        <AndroidTab data={data} type="android" />
        <AppleTab data={data} type="apple" />
        <OtherTab data={data} type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query colorsResources {
    allColorsYaml {
      edges {
        node {
          type
          colors {
            value
            hex_value
            android_var
            sass_var
            apple_var
            rn_var
          }
          light_environment_colors {
            value
            hex_value
            android_var
            sass_var
            apple_var
            rn_var
          }
          dark_environment_colors {
            value
            hex_value
            android_var
            sass_var
            apple_var
            rn_var
          }
        }
      }
    }
  }
`;
