import React from 'react';
import { ColorDataTableCode, Section } from 'docComponents';

const Identity = ({ data, platform }) => {
  const identityData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Identity'
  );

  return (
    <Section title="Identity">
      <ColorDataTableCode data={identityData} platform={platform} />
    </Section>
  );
};

export default Identity;
