import React from 'react';
import { ColorDataTableCode, Section, SectionSubhead } from 'docComponents';

const Content = ({ data, platform }) => {
  const textData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Text'
  );
  const iconData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Icon'
  );

  return (
    <Section title="Content">
      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={iconData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={iconData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={textData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={textData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Content;
