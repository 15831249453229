import React from 'react';
import { ColorDataTableCode, Section, SectionSubhead } from 'docComponents';

const Themes = ({ data, platform }) => {
  const backgroundData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Background'
  );
  const baseData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Base'
  );
  const dividerData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Divider'
  );

  return (
    <Section title="Themes">
      <Section>
        <ColorDataTableCode data={baseData} platform={platform} />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={backgroundData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={backgroundData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={dividerData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={dividerData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Themes;
