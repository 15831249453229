import React from 'react';
import { ColorDataTableCode, Section, SectionSubhead } from 'docComponents';

const Utility = ({ data, platform }) => {
  const utilityData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Utility'
  );
  const utilityAccentData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Utility-Accent'
  );

  return (
    <Section title="Utility">
      <Section>
        <ColorDataTableCode data={utilityData} platform={platform} />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={utilityAccentData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={utilityAccentData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Utility;
