import React from 'react';
import { PlatformTab } from 'docComponents';
import Brand from '../partials/_Brand';
import Components from '../partials/_Components';
import Content from '../partials/_Content';
import Identity from '../partials/_Identity';
import Tagging from '../partials/_Tagging';
import Themes from '../partials/_Themes';
import Utility from '../partials/_Utility';
import SectionPageNav from '../partials/_SectionPageNav';

const AppleTab = ({ data }) => {
  return (
    <PlatformTab>
      <SectionPageNav />
      <Brand data={data} platform="apple" />
      <Components data={data} platform="apple" />
      <Content data={data} platform="apple" />
      <Identity data={data} platform="apple" />
      <Tagging data={data} platform="apple" />
      <Themes data={data} platform="apple" />
      <Utility data={data} platform="apple" />
    </PlatformTab>
  );
};

export default AppleTab;
