import React from 'react';
import { PageNavigation, PageNavigationLink } from 'docComponents';

const SectionPageNav = () => {
  return (
    <PageNavigation>
      <PageNavigationLink>Brand</PageNavigationLink>
      <PageNavigationLink>Component</PageNavigationLink>
      <PageNavigationLink>Content</PageNavigationLink>
      <PageNavigationLink>Identity</PageNavigationLink>
      <PageNavigationLink>Tagging</PageNavigationLink>
      <PageNavigationLink>Themes</PageNavigationLink>
      <PageNavigationLink>Utility</PageNavigationLink>
    </PageNavigation>
  );
};

export default SectionPageNav;
